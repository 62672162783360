body {
    min-height: 100%;
    background: #f0f0f1;
    margin: 0;
}

.text-center {
    text-align: center;
    justify-content: center;
}

.p-0 {
    padding: 0!important;
}